import {ref} from "vue";

const BREAKPOINTS = [
  {
    length: 0,
    name: 'xs'
  },
  {
    length: 576,
    name: 'sm'
  },
  {
    length: 768,
    name: 'md'
  },
  {
    length: 992,
    name: 'lg'
  },
  {
    length: 1200,
    name: 'xl'
  },
  {
    length: 1600,
    name: 'xxl'
  },
  {
    length: 2559,
    name: 'qhd' // 2K resolution
  },
  {
    length: 3839,
    name: 'uhd' // 4K resolution
  },
].sort((a, b) => b.length - a.length )

function calculateBreakpoint(width) {
  breakpoint.current.value = breakpoint.breakpoints.find(breakpoint => breakpoint.length < width).name;
}

const breakpoint = {
  current: ref(null),
  breakpoints: BREAKPOINTS,
  less(breakpoint) {
    return this.breakpoints.findIndex(bp => bp.name === this.current.value) > this.breakpoints.findIndex(bp => bp.name === breakpoint)
  },
  lessEquals(breakpoint) {
    return this.breakpoints.findIndex(bp => bp.name === this.current.value) >= this.breakpoints.findIndex(bp => bp.name === breakpoint)
  },
  equals(breakpoint) {
    return this.breakpoints.findIndex(bp => bp.name === this.current.value) === this.breakpoints.findIndex(bp => bp.name === breakpoint)
  },
  greaterEquals(breakpoint) {
    return this.breakpoints.findIndex(bp => bp.name === this.current.value) <= this.breakpoints.findIndex(bp => bp.name === breakpoint)
  },
  greater(breakpoint) {
    return this.breakpoints.findIndex(bp => bp.name === this.current.value) < this.breakpoints.findIndex(bp => bp.name === breakpoint)
  }
};
calculateBreakpoint(window.innerWidth)
window.addEventListener('resize', (event)=> calculateBreakpoint(event.currentTarget.innerWidth))

export default breakpoint;